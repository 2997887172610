<template>
  <v-row class="pb-10" v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Emisión por lotes" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="['generating', 'generated'].includes(instance.status)" class="mx-2" @click="handleRandomPDF" outlined :disabled="!['generated'].includes(instance.status)"><v-icon left>mdi-shuffle-variant</v-icon>Revisión aleatoria</v-btn>
        <v-btn v-if="['pending', 'generating', 'generated'].includes(instance.status) && $helpers.hasSomePermission(['delete_batch'])"  @click="dialogDelete = true" outlined><v-icon left>mdi-login-variant</v-icon>Abortar proceso</v-btn>
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" color="primary" v-bind="attrs" v-on="on">
              <v-icon left>mdi-download</v-icon>Descargar<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" :href="instance.source" download :ripple="false">
              <v-list-item-title class="body-2">Fuente de datos</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" v-if="instance.status !== 'pending'" @click="createExport" :ripple="false">
              <v-list-item-title class="body-2">Excel de errores</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </mini-header>
    <!-- end header -->

    <!-- loader -->
    <v-col cols="12" class="mt-20 px-0" v-if="loading && !loadingNextStage">
      <v-skeleton-loader class="rounded-t-md" transition="fade-transition" type="card-heading"/>
      <v-skeleton-loader class="rounded-0" transition="fade-transition" type="image"/>
      <v-skeleton-loader class="rounded-b-md" transition="fade-transition" type="table-tbody" />
    </v-col>
    <!-- end loader -->

    <v-col cols="12" class="mt-20 px-0" v-else>
      <v-card flat>
        <!-- stepper -->
        <v-col cols="11" class="text-center mx-auto pa-0 pb-3 pt-8" style="min-width: 800px;">
          <div class="d-flex justify-center" :class="{ 'pb-4' : !['pending', 'generating', 'generated', 'processing', 'processed', 'declaring', 'declared'].includes(instance.status) || !$helpers.hasSomePermission(['add_batch'])}">
            <div class="text-center" v-for="(step, index) in stepperList" :key="index">
              <v-sheet class="transparent" min-width="110" style="z-index: 0; position: relative">
                <v-avatar size="30" :style="stepStyle(step, index)">
                  <v-icon
                    :class="{'spinner' : instance.status !== 'finalized' && instance.status.search(/ing/) !== -1 && step.status.includes(instance.status)}"
                    :color="stepIconColor(step, index)"
                    size="15">mdi-{{ instance.status !== 'finalized' && step.status.includes(instance.status) && instance.status.search(/ing/) !== -1 ? 'autorenew' : step.icon }}
                  </v-icon>
                </v-avatar>
                <span class="d-block mt-1 fontBody--text body-1 pt-1">{{ step.title }}</span>
              </v-sheet>
              <v-divider
                class="line-stepper"
                v-if="(stepperList.length - 1 !== index) && (index >= 0 && index < stepperList.findIndex((item) => item.status.includes(instance.status)))" style="border: 1px solid var(--light-blue-primary);" />
              <v-divider
                class="line-stepper"
                v-else-if="stepperList.length - 1 !== index"
                style="border: 1px solid var(--light-blue-grey);" />
            </div>
          </div>
          <v-btn
            class="mx-auto my-4 subtitle-2 font-weight-medium"
            color="primary"
            :ripple="false"
            @click="['processed', 'declared'].includes(instance.status) ? dialogProcess = true : nextStage()"
            v-if="['pending', 'generating', 'generated', 'processing', 'processed', 'declaring', 'declared'].includes(instance.status) && $helpers.hasSomePermission(['add_batch'])"
            :disabled="!['pending', 'generated', 'processed', 'declared'].includes(instance.status)">Ir a la siguiente etapa</v-btn>
        </v-col>
        <!-- end stepper -->
        <div v-if="!!stepperList.find((item) => item.status.includes(instance.status)).alert" style="border-top: 1px solid var(--light-blue-primary)">
          <v-alert class="inf-alert primary--text alert-fl" :height="50" dense text color="#BED2F0" tile>
            <div class="d-flex px-2 py-0">
              <v-icon color="primary" size="26">mdi-information</v-icon>
              <div class="ml-4 py-1">
                <span class="body-1" v-html="stepperList.find((item) => item.status.includes(instance.status)).alert"></span>
              </div>
            </div>
          </v-alert>
        </div>
      </v-card>

      <!-- <v-divider /> -->
      <v-card class="mb-2 mt-4 primary" height="95" flat>
        <v-col :class="['pa-0', {'mt-0' : ['processing', 'processed', 'declared', 'declaring', 'distributing'].includes(instance.status)}]">
          <div class="d-flex pa-10 py-6">
            <v-sheet class="transparent mr-8" width="170">
              <span class="d-block body-1 white--text">Fecha</span>
              <span class="white--text subtitle-2 font-weight-semibold">{{instance.created | dateTimeSecond}}</span>
            </v-sheet>
            <v-sheet class="transparent mx-8" width="200">
              <span class="d-block body-1 white--text">Usuario</span>
              <span class="white--text subtitle-2 font-weight-semibold mr-1" v-if="instance.user">{{instance.user}}</span>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </v-sheet>
            <v-sheet class="transparent mx-8" width="130">
            <span class="d-block body-1 white--text">Cantidad de DTE</span>
            <span class="white--text subtitle-2 font-weight-semibold mr-1">
              <v-btn class="mt-n1" @click="getKPI" text color="white" :ripple="false">Ver detalles</v-btn>
            </span>
          </v-sheet>
            <template v-if="!['pending', 'finalized'].includes(instance.status) && timeLeft">
              <v-sheet class="transparent mx-8" width="130">
                <span class="d-block body-1 white--text">Tiempo restante</span>
                  <span class="white--text subtitle-2 font-weight-semibold mr-1">{{ getTimeLeft }}</span>
                  <div style="width: 130px;">
                    <v-progress-linear class="rounded-md" color="white" v-model="getProgressTimeLeft" :active="progress.show" :indeterminate="progress.query" :query="true" />
                  </div>
              </v-sheet>
            </template>
          </div>
        </v-col>
      </v-card>
      <v-card flat :class="[{'transparent' : batchList.length || documentsList.length}]">
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 pt-2" :class="{'pb-2' : !documentsList.length}">
              <skeleton-list v-if="loadingBatchDocument" />
              <template v-else>
                <v-empty-state v-if="!batchList.length && !documentsList.length" type="documents" id="documentos" :isFree="true" customClass="mt-3" title="No existen documentos para mostrar" />
                <template v-else>
                  <template v-if="!['processed', 'declaring', 'declared', 'distributing', 'distributed', 'finalized'].includes(instance.status)">
                    <v-data-table
                      class="table-not-rounded"
                      :headers="headers"
                      :items="batchList"
                      :items-per-page="pagination"
                      hide-default-header
                      disable-sort
                      item-key="id"
                      hide-default-footer
                      mobile-breakpoint="300"
                      @click:row="(documentsList) => handlerDetail(documentsList)"
                      style="cursor: pointer"
                    >
                      <!-- header -->
                      <template v-slot:header="{ props: { headers } }">
                        <VTableHeaders :headers="headers" />
                        <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
                      </template>
                      <!-- end header -->
                      <template v-slot:[`item.data.taxpayer`]="{item}">
                        <template v-if="!!Object.keys(item.data.customer || {}).length">
                          <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;">{{((item.data || {}).customer || {}).name}}</span>
                          <span class="caption fontDraw--text d-block mt-n1" v-if="((item.data || {}).customer || {}).taxpayer">{{$t('generals.RUT')}} {{item.data.customer.taxpayer}}</span>
                          <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </template>
                        <template v-else-if="!!Object.keys(item.data.taxpayer || {}).length">
                          <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;">{{((item.data || {}).taxpayer || {}).name}}</span>
                          <span class="caption fontDraw--text d-block mt-n1" v-if="((item.data || {}).taxpayer || {}).tax_id">{{$t('generals.RUT')}} {{item.data.taxpayer.tax_id}}</span>
                          <span class="d-block body-2 mt-n1 text--disabled font-italic" v-else>Sin especificar</span>
                        </template>
                        <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </template>
                      <template v-slot:[`item.data.document_type`]="{item}">
                        <span>{{item.data.document_type | nameDocumentsType}}</span>
                      </template>
                      <template v-slot:[`item.data.number`]="{item}">
                        <span class="body-1">{{$store.state?.auth?.account?.country === 'PE' ? `${item.data.prefix} - ${item.data.number}` : item.data.number }}</span>
                      </template>
                      <template v-slot:[`item.data.date`]="{item}">
                        <span class="body-1">{{item.data.date | date}}</span>
                      </template>
                    </v-data-table>
                    <template>
                      <VMainPagination class="px-5 pb-5" :count="countDocumentsBatch" @isActive="getDocumentByBatch" />
                    </template>
                  </template>
                  <div v-else>
                    <documents-list style="position: relative !important;" :show-pagination="false" :has-link="false" :sortable="false" alert="Esta visualización es una vista previa del documento, por lo que no es válido legalmente." />
                    <div class="mb-0" v-if="countDocList > 14" style="position: absolute;">
                      <v-row class="px-1 pt-4" align="center">
                        <v-btn class="ml-3 body-1" :to="{name: 'DocumentsList', query: { batchdocument__batch: instance.id } }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- dialog confirm process-->
    <v-dialog v-model="dialogProcess" width="650" persistent no-click-animation grey-500-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">{{ instance.status  === 'declared' ? 'Distribución' : 'Declaración' }} de documentos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogProcess=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-confirm.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-3 pl-1">
              <span class="d-block text-body-1">Al continuar, no hay vuelta atrás ya que los documentos se enviarán a SII.</span>
              <span class="d-block text-body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogProcess=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handleConfirm" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm process -->

    <!-- dialog PDF -->
    <v-dialog v-model="dialogRandomReviewPDF" width="700" persistent no-click-animation scrollable>
      <v-card>
        <template>
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Revisión aleatoria</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogRandomReviewPDF=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20" >mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
        </template>
        <v-card-text class="pa-0">
          <v-alert class="body-2 pl-5 inf-alert primary--text" v-show="!loaderRender && renderUrl && !pdfError" :height="34" dense text outlined color="#BED2F0" tile>Esta visualización es una vista previa del documento, por lo que no es válido legalmente.</v-alert>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="py-0 px-4 ma-0" v-if="loaderRender">
              <v-skeleton-loader class="rounded-0 pa-0" type="table" />
            </v-col>
            <v-col v-else-if="pdfError">
              <v-empty-state class="mt-4 py-10" :isFree="true" title="No se puede previsualizar el documento porque contiene errores." customClass="mt-6" :height="100" type="pdf" />
            </v-col>
          </v-row>
          <div class="mt-n4" v-show="!loaderRender && renderUrl && !pdfError" id="render"></div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-btn outlined @click="index > 0 ? handlePDF('previous') : ''" :disabled="index === 0">Anterior</v-btn>
          <v-spacer />
          <v-btn
            outlined
            @click="index >= 0  && index < (instance.random_preview.length - 1) ? handlePDF() : ''"
            :disabled="index === (instance.random_preview || []).length - 1"
            :loading="loaderRender"
            >Siguiente</v-btn>
            <v-btn color="primary" dark @click="dialogRandomReviewPDF=false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- document detail dialog -->
      <DocumentRowDetail v-if="dialogDetail" :item="current" @close="dialogDetail = false" />
    <!-- end document detail dialog -->

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="650" persistent no-click-animation grey-500-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Abortar proceso</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-confirm.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-3 pl-1">
              <span class="d-block text-body-1">Recuerda que esta confirmación permite abortar el proceso y no podrá volver atrás.</span>
              <span class="d-block text-body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroy" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog detail -->
    <v-dialog v-model="showDetail" width="650" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Cantidad de DTE</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="showDetail=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col>
              <img :src="require(`@/assets/backgrounds/modal-el-detail.svg`)" :height="168" />
            </v-col>
            <v-col cols="7" class="pr-3 pl-0">
              <div class="d-flex align-center justify-space-between">
                <div>Documentos del lote</div>
                <div>{{ kpi.quantity | number }}</div>
              </div>
              <div class="my-4 d-flex align-center justify-space-between">
                <div>Documentos procesados</div>
                <div>{{ kpi.ok | number }}</div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div>Documentos con errores</div>
                <div>{{ kpi.error | number }}</div>
              </div>
              <template v-if="['declared', 'declaring', 'distributing', 'distributed', 'finalized'].includes(instance.status)">
                <div class="my-4 d-flex align-center justify-space-between">
                  <div>Documentos aceptados por SII</div>
                  <div>{{ kpi.ok_sii | number }}</div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div>Documentos rechazados por SII</div>
                  <div>{{ kpi.error_sii | number }}</div>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn color="primary" @click="showDetail=false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { saveAs } from 'file-saver'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import MiniHeader from '@/components/commons/MiniHeader'
import VEmptyState from '@/components/commons/VEmptyState'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import ChipStatus from '@/components/commons/ChipStatus'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VMainPagination from '@/components/commons/VMainPagination'
import DocumentRowDetail from '@/modules/documents/batch/components/DocumentRowDetail'

export default {
  mixins: [
    GenericViewMixin, RenderViewMixin, ListViewMixin
  ],
  components: {
    SkeletonList,
    VEmptyState,
    MiniHeader,
    ChipStatus,
    DocumentsList,
    VTableHeaders,
    VMainPagination,
    DocumentRowDetail
  },
  data: () => ({
    kpi: {},
    pdfError: false,
    showDetail: false,
    countDocList: 0,
    countBatch: false,
    dialogExport: false,
    extensionFile: 'xlsx',
    hiddenTimeLeft: false,
    totalTime: 0,
    timeLeftInterval: 0,
    time: null,
    timeLeft: null,
    dialogDelete: false,
    loadingTransition: false,
    scroll: 0,
    index: 0,
    dialogDetail: false,
    dialogRandomReviewPDF: false,
    confirm: '',
    dialogProcess: false,
    loading: false,
    loadingBatchDocument: false,
    loadingNextStage: false,
    instance: {},
    current: {},
    stepperList: [
      {
        status: ['pending'],
        title: 'Previsualizar',
        icon: 'file-search'
      },
      {
        status: ['generating', 'generated', 'generate'],
        title: 'Generar',
        icon: 'cog',
        ms: 250
      },
      {
        status: ['processing', 'processed', 'process'],
        title: 'Procesar',
        icon: 'draw',
        ms: 250,
        alert: 'Durante esta etapa, los <span class="font-weight-bold">documentos subidos, son timbrados y firmados.</span>'
      },
      {
        status: ['declaring', 'declared'],
        title: 'Declarar',
        icon: 'bank',
        ms: 2000,
        alert: 'Durante esta etapa, <span class="font-weight-bold">cada documento</span> creado a partir del batch <span class="font-weight-bold">se envía al SII.</span> '
      },
      {
        status: ['distributing', 'distributed'],
        title: 'Distribución',
        icon: 'send-clock',
        ms: 500,
        alert: 'Durante esta etapa, <span class="font-weight-bold">cada documento</span> creado a partir del batch, <span class="font-weight-bold">se envía a los emails pertinentes.</span>'
      },
      {
        status: ['finalized'],
        title: 'Completado',
        icon: 'check'
      }
    ],
    headers: [
      { text: 'Cliente', value: 'data.taxpayer', width: 380},
      { text: 'Tipo de documento', value: 'data.document_type', width: 250 },
      { text: 'Folio #', value: 'data.number', align: 'end', width: 200 },
      { text: 'Fecha', value: 'data.date', align: 'end', width: 250 }
    ],
    batchList: [],
    progress: {
      value: 0,
      query: false,
      show: true,
      interval: 0
    },
    transition: [],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Emisión por lotes',
          route: 'DocumentsBatchListCreate'
        }
      ]
    }
   }),
  watch: {
    'instance.status': {
      handler (val) {
        if (val !== 'pending' && val.search(/ing/) !== -1) this.getTransition()
      }
    },
    loadingTransition: {
      handler (val) {
        if (!val) {
          this.getTime()
        }
      },
      deep: true
    },
    '$route' (val) {
      if (Object.keys(val.query).includes('ordering')) this.getDocumentByBatch()
    }
  },
  created () {
    this.retrieve()
  },
  computed: {
    ...mapState({
      countDocuments: state => state.documents.documentsCount,
      countDocumentsBatch: state => state.batch.batch_documentsCount,
      batchDetail: state => state.batch.batchDetail,
      list: state => state.batch.batch_documentsList,
      documentsList: state => state.documents.documentsList
    }),
    number () {
      return this.$store.state?.auth?.account?.country === 'PE' ? `${this.current.prefix} - ${this.current.number}` : this.current.number
    },
    stepStyle () {
      return (step, index) => {
        if (this.instance.status !== 'finalized' && this.instance.status.search(/ing/) !== -1 && step.status.includes(this.instance.status)) return 'border: 2px solid var(--light-blue-primary); background: var(--light-white-1);'
        if (index >= 0 && index <= this.stepperList.findIndex((item) => item.status.includes(this.instance.status))) return 'background-color: var(--light-blue-primary)'
        return 'border: 2px solid var(--light-blue-grey); background: var(--light-white-1);'
      }
    },
    stepIconColor () {
      return (step, index) => {
        if (this.instance.status !== 'finalized' && this.instance.status.search(/ing/) !== -1 && step.status.includes(this.instance.status)) return 'var(--light-blue-primary)'
        if (index >= 0 && index <= this.stepperList.findIndex((item) => item.status.includes(this.instance.status))) return 'white'
        return 'var(--light-blue-grey)'
      }
    },
    getTimeLeft () {
      const hours = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('HH')) : 0
      const minutes = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('mm')) : 0
      const seconds = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('ss')) : 0
      if (hours === 0 && (minutes > 0 || minutes <= 59) && minutes > 0) return `${minutes}:${seconds} ${minutes === 1 ? 'minuto' : 'minutos'}`
      if (minutes > 0 || minutes < 60) return `00:${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`
      return `${hours}:${minutes}:${seconds} ${hours === 1 ? 'hora' : 'horas'}`
    },
    getProgressTimeLeft () {
      return this.timeLeft ? 100 - ((this.timeLeft * 100) / this.totalTime) : 100
    }
  },
  methods: {
    getKPI () {
      this.showDetail = true
      this.$store.dispatch('batch/GET', {
        resource: `batch/${this.$route.params.id}/kpi/`
      })
      .then((resp) => {
        this.kpi = resp.data
      })
      .finally(() => {
        // this.showDetail = false
      })
    },
    async getTime () {
      const { ms } = this.stepperList.find((item) => item.status.includes(this.instance.status))
      this.progress.interval = ms ? ((this.instance.count / 10) * ms) / this.instance.count : ''

      let current = this.stepperList.find(({status}) => status.includes(this.instance.status)).status
     /* eslint-disable */
      let transitionCurrent = this.transition.find(({status_to}) => current.includes(status_to))

      if (!!transitionCurrent?.end) {
        const end = moment(transitionCurrent.end).format()
        const start = moment(end).add(this.progress.interval, 's').format()
        const duration = moment.duration((moment(start).diff(moment())))
        const sec = duration.seconds()
        this.timeLeft = sec + (this.progress.interval * 1000)
        this.totalTime = sec + (this.progress.interval * 1000) // progress
        if (sec > 0) {
          this.timeLeftInterval = setInterval(() => {
            if (this.timeLeft >= 1000) {
              this.timeLeft -= 1000
            } else {
              this.detenerTemporizador()
            }
          }, 1000)
        } else {
          this.detenerTemporizador()
        }
      }
    },
    getTransition () {
      this.loadingTransition = true
      this.$store.dispatch('batch/GET', {
        resource: 'batch_transition/',
        query: {
          batch: this.$route.params.id
          // status_to: this.instance.status
        }
      })
      .then((resp) => {
        this.transition = resp.data.results
      })
      .finally(() => {
        this.loadingTransition = false
      })
    },
    detenerTemporizador () {
      clearInterval(this.timeLeftInterval)
      this.timeLeft = 0
      this.$store.dispatch('batch/RETRIEVE', {
      resource: 'batch',
        id: this.$route.params.id
      })
      .then(() => {
        this.instance = {...this.batchDetail}
        this.getDocumentByBatch()
      })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handlerDetail (item = {}) {
      if (['processed', 'declared', 'distributing', 'distributed', 'finalized'].includes(this.instance.status)) {

      } else {
        this.current = item
        this.dialogDetail = true
      }
    },
    retrieve () {
      this.loading = true
      this.$store.dispatch('batch/RETRIEVE', {
        resource: 'batch',
        id: this.$route.params.id
      })
      .then(() => {
        this.instance = {...this.batchDetail}
        this.breadcrumbs.children.push({ text1: this.instance.id})
        this.getDocumentByBatch()
        this.loading = false
      })
    },
    handleConfirm () {
      this.nextStage()
      this.dialogProcess = false
      this.confirm = ''
    },
    nextStage () {
      this.loadingNextStage = true
      this.$store.dispatch('batch/LIST', {
        resource: `batch/${this.$route.params.id}/next_stage`,
        loader: false
      })
      .then((resp) => {
        this.instance.status = resp.data.status
        this.getDocumentByBatch()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    getDocumentByBatch () {
      this.loadingBatchDocument = true
      this.countBatch = false
      this.countDocList = 0
      const status = ['processed', 'declaring', 'declared', 'distributing', 'distributed', 'finalized'].includes(this.instance.status)
      this.$store.dispatch('batch/LIST', {
        resource: 'batch_documents',
        query: {
          batch: this.$route.params.id,
          ...(status && { status: this.instance.status, expand: 'document' }),
          ...this.$route.query
        }
      })
      .then((res) => {
        if (status) {
          let response = {...res}
          response.data.results = res?.data?.results?.map(({document}) => {
            return { document }
          }).filter(({document}) => !!document)
          this.countDocList = response.data.results.length
          response.data.results = response.data.results.length > 14 ? response.data.results.map(({document}) => document).slice(0, 14) : response.data.results.map(({document}) => document)
          this.$store.commit('documents/SET_LIST', { response: response, resource: 'documents'})
        } else {
          this.countBatch = this.countDocumentsBatch
          this.batchList = [...this.list]
        }
      })
      .finally(() => {
        this.loadingBatchDocument = false
      })
    },
    createExport () {
      this.$store.dispatch('batch/LIST', {
        resource: `batch/${this.$route.params.id}/export_error`,
        query: {
          extension: this.extensionFile
        },
        loader: false,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], {type: 'application/xlsx'}), 'excel de errores.xlsx')
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch((response) => {
        if (response.toString().search('400') !== -1) {
          this.$dialog.message.info('No hay errores en el proceso del batch')
        } else this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
      .finally(() => {
        this.dialogExport = false
      })
    },
    handlePDF (type = 'next') {
      this.index = type === 'next' ? this.index + 1 : this.index - 1
      this.handleRandomPDF()
    },
    handleRandomPDF () {
      this.dialogRandomReviewPDF = true
      this.randomReviewPDF()
    },
    randomReviewPDF() {
      this.pdfError = false
      this.loaderRender = true
      this.$store.dispatch(`batch/RETRIEVE`, {
        resource: 'batch_documents',
        detail: 'pdf',
        id: this.instance.random_preview[this.index],
        setDetail: false,
        loader: false
      })
      .then((response) => {
        const byteCharacters = atob(response.data.content)
        const byteNumbers = new Array(byteCharacters)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const file = new Blob([byteArray], {type: 'application/pdf'})
        this.renderUrl = URL.createObjectURL(file)
        this.$nextTick(() => {
          window.PDFObject.embed(this.renderUrl, '#render')
        })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
        this.pdfError = true
      })
      .finally(() => {
        this.loaderRender = false
      })
    },
    destroy () {
      this.$store.dispatch('batch/DESTROY', {
        resource: 'batch',
        id: this.$route.params.id
      })
      .then(() => {
        this.$router.push({ name: 'DocumentsBatchListCreate' })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDelete = false
      })
    }
  }
}
</script>
<style scoped>
.line-stepper {
  position: relative !important;
  top: -42px;
  z-index: 0;
  width: 80px;
  left: 70px;
}
.spinner {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>